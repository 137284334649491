<template>
  <div class="vouchers-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Gift Vouchers</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="500">
        <v-text-field
          class="field46 mt-sm-0 mt-2"
          v-model="search"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-slot:header.email_sent_at>
            Delivery time
            <v-btn
              @click="sortItems('delivery_date')"
              icon
              class="btn-sort"
              :class="sort.some((i) => i == 'delivery_date_asc' || i == 'delivery_date_desc') ? 'primary white--text' : ''"
            >
              <v-icon>
                {{ sort.some((i) => (i == 'delivery_date_asc' ? true : false)) ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin' }}
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:header.status>
            Status
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="status != 'all' ? 'primary white--text' : ''">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="status == item.id ? 'primary--text font-weight-bold' : ''"
                  class="text-body-2"
                  link
                  v-for="item in itemsStatus"
                  :key="item.id"
                  @click="(status = item.id), getData()"
                  style="min-height: 36px"
                >
                  {{ item.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:header.created_at>
            Submitted at
            <v-btn
              @click="sortItems('created_at')"
              icon
              class="btn-sort"
              :class="sort.some((i) => i == 'created_at_asc' || i == 'created_at_desc') ? 'primary white--text' : ''"
            >
              <v-icon>{{ sort.some((i) => (i == 'created_at_asc' ? true : false)) ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin' }}</v-icon>
            </v-btn>
          </template>
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openItem(item.number)" style="height: 48px">
                <td>{{ item.number }}</td>
                <td>{{ item.voucher_offer.title_en }}</td>
                <td class="text-center">
                  <v-icon color="primary">{{ item.product_order ? 'mdi-check' : 'mdi-close' }}</v-icon>
                </td>
                <td>SAR {{ item.amount / 100 }}</td>
                <td>SAR {{ item.total / 100 }}</td>
                <td class="text-capitalize">{{ item.status }}</td>
                <td>{{ item.created_at ? new Date(item.created_at).toLocaleString('en-GB').split('/').join('.') : '' }}</td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }} {{ data.total > 1 ? 'vouchers' : 'voucher' }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openItem(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Number</div>
              <div class="font-weight-medium f18">{{ item.number }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Voucher</div>
              <div class="font-weight-medium f18">{{ item.voucher_offer.title_en }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Gift included</div>
              <v-icon color="primary">{{ item.product_order ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Amount</div>
              <div class="font-weight-medium f18 text-truncate">SAR {{ item.amount / 100 }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Total</div>
              <div class="font-weight-medium f18 text-truncate">SAR {{ item.total / 100 }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Status</div>
              <div class="text-capitalize font-weight-medium f18">{{ item.status }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Submitted at</div>
              <div class="font-weight-medium f18">{{ new Date(item.created_at).toLocaleString('en-GB').split('/').join('.') }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }} {{ data.total > 1 ? 'vouchers' : 'voucher' }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      search: this.$route.query.src || '',
      status: this.$route.query.status || 'all',
      sort: this.$route.query.sort ? this.$route.query.sort.split(',') : [],
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Number', value: 'number', sortable: false },
        { text: 'Voucher', value: 'voucher', sortable: false },
        { text: 'Gift included', value: 'product_order', sortable: false, class: 'table-center-column' },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Total', value: 'total', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Submitted at', value: 'created_at', sortable: false },
      ],
      itemsStatus: [
        { id: 'all', title: 'All' },
        { id: 'paid', title: 'Paid' },
        { id: 'delivered', title: 'Delivered' },
        { id: 'redeemed', title: 'Redeemed' },
        { id: 'canceled', title: 'Canceled' },
      ],
      statusError: null,
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openItem(id) {
      this.$router.push(`/gift-vouchers/${id}`);
    },
    sortItems(c) {
      if (this.sort.some((i) => i == `${c}_desc`)) {
        const idx = this.sort.indexOf(`${c}_desc`);
        this.sort.splice(idx, 1);
      } else if (this.sort.some((i) => i == `${c}_asc`)) {
        const idx = this.sort.indexOf(`${c}_asc`);
        this.sort.splice(idx, 1);
        this.sort.push(`${c}_desc`);
      } else {
        this.sort.push(`${c}_asc`);
      }
      this.$nextTick(() => {
        this.getData();
      });
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      this.statusError = null;
      await this.$store
        .dispatch('getVouchersList', {
          search: this.search,
          status: this.status,
          sort: this.sort,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.vouchersList;
    },
    profile() {
      return this.$store.getters.profile;
    },
  },
  destroyed() {
    this.$store.dispatch('setVouchersList');
  },
};
</script>

<style lang="scss">
.vouchers-list {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
